import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/layout';
import Carousel from '../components/carousel';
import SEO from '../components/seo';
import RestaurantsBox from '../components/restaurantsBox';
import LoginCard from '../components/loginCard';
import { setInsiderPageType } from '../utils/insiderUtils';
import { analyticsStartUsingView } from '../utils/analyticsUtils';

const query = graphql`
  query {
    allStrapiBrand(sort: { fields: DisplayOrder }) {
      edges {
        node {
          CRMId
          DisplayOrder
          Name
          NormalizedName
          TiklaParamEarnings
          Splash {
            publicURL: url
          }
          Logo {
            publicURL: url
            alternativeText
          }
        }
      }
    }

    allStrapiExternalBrand {
      edges {
        node {
          Name
          BrandId
          Logo {
            url
          }
          Slug
        }
      }
    }

    allStrapiLandingPage {
      edges {
        node {
          id
          Slug
          PageType
          Banner {
            url
            alternativeText
          }
          DisplayOrder
          Title
          TargetSlider
          Brand {
            Name
            Splash {
              publicURL: url
            }
            Logo {
              publicURL: url
            }
          }
          StartDate
          EndDate
          DisplayStartTime
          DisplayEndTime
          ExternalUrl
          ProductList {
            ShortText
            Product {
              Name
              CrmId
              Image {
                publicURL: url
                alternativeText
              }
              BrandCrmId
            }
          }
        }
      }
    }

    allApiProduct {
      nodes {
        id
        products {
          categoryName
          subcategories {
            name
            products {
              name
            }
          }
        }
      }
    }

    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

function IndexPage({ location }) {
  const [translate] = useTranslation();
  const title = translate('seo.homepage.title');
  const description = translate('seo.homepage.description');
  const schema = {
    '@type': 'Organization',
    '@context': 'http://schema.org',
    name: 'Tıkla Gelsin',
    url: 'https://www.tiklagelsin.com/',
    logo: '',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'İstanbul',
      addressRegion: 'TR',
      streetAddress: 'Dikilitaş Mahallesi Emirhan Caddesi No:109 Beşiktaş / İstanbul',
    },
    sameAs: [
      'https://apps.apple.com/tr/app/t%C4%B1kla-gelsin/id1534477610?l=tr',
      'https://play.google.com/store/apps/details?id=com.ataexpress.tiklagelsin',
    ],
  };

  useEffect(() => {
    analyticsStartUsingView();
    setInsiderPageType('Home');
  }, []);
  return (
    <>
      <StaticQuery
        query={query}
        render={(data) => (
          <>
            <SEO
              title={title}
              description={description}
              siteUrl={location?.href}
              schemaMarkup={schema}
              deepLinkUrl="home"
            />
            <Layout home searchData={data?.allApiProduct?.nodes} searchEnabled preferApp location={location}>
              <LoginCard className="home" />
              <Carousel type="Main" items={data?.allStrapiLandingPage?.edges} />
              <RestaurantsBox
                staticBrands={data?.allStrapiBrand?.edges}
                externalBrands={data?.allStrapiExternalBrand?.edges}
              />
            </Layout>
          </>
        )}
      />
    </>
  );
}

IndexPage.propTypes = {
  location: PropTypes.object,
};

export default IndexPage;
